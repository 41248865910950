<template>
  <div class="home_wrapper">
    <main id="home_main">
      <section class="parallax pricing_bg">
        <v-container
          fill-height
          fluid
        >
          <v-row   
            vertical-align="bottom"
            class="override_home_welcome"
          >
            <v-col
              align="end" 
              class="text-center"
              cols="12"
            >
              <v-row class="my-8">
                <v-col class="text-center">
                  <h1 class="text-h3 mb-8 text-center force-center-title">
                    Pricing
                  </h1>
                  <p class="text-h5">
                    No monthly fees, pay a tiny percentage of any fees taken.
                  </p>
                  <br>
                  <v-btn
                    color="homebuttonbg"
                    class="homebuttoncolour--text"
                    x-large
                    @click="$vuetify.goTo('#section_price', options)"
                  >
                    <v-icon left>
                      mdi-rocket-launch
                    </v-icon>
                    Find out more
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section
        id="section_price"
        class="no-parallax"
      >
        <v-row>
          <v-col
            md="8"
            offset-md="2"
          >
            <v-card class="my-8 pa-4">
              <v-card-title class="d-flex justify-space-between mt-0 mb-4">
                <h2 class="text-h5">
                  Zero monthly cost
                </h2>
                <h3 class="text-h4">
                  £0 <small>per month!</small>
                </h3>
              </v-card-title>
              <v-card-text class="text-body-1">
                We believe aviation doesn't have to cost the earth!
              </v-card-text>
            </v-card>

            <v-card class="my-8 pa-4">
              <v-card-title class="d-flex justify-space-between mt-0 mb-4">
                <h2 class="text-h5">
                  Small transaction fee
                </h2>
                <h3 class="text-h4">
                  3% <small>per transaction</small>
                </h3>
              </v-card-title>
              <v-card-text class="text-body-1">
                <p>When taking payments through us for landing fees and overnight parking, we charge a tiny percentage on top of our payment provider Stripe.</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </section>
      <section class="parallax pricing_bg2">
        <div class="d-flex flex-column justify-space-between align-center my-8">
          <v-btn
            color="homebuttonbg"
            class="homebuttoncolour--text"
            x-large
            to="/register"
          >
            <v-icon left>
              mdi-rocket-launch
            </v-icon>
            Sign up for free
          </v-btn>
        </div>
      </section>
      <section class="no-parallax longer_content">
        <v-row>
          <v-col
            md="8"
            offset-md="2"
          >
            <v-row class="mt-8 mb-4">
              <v-col>
                <v-card class="my-8 pa-4">
                  <v-card-title class="d-flex justify-space-between mt-0 mb-4">
                    <h2 class="text-h5">
                      Example costs
                    </h2>
                  </v-card-title>
                  <v-card-text class="text-body-1">
                    <p>Our payment provider Stripe, varies their fee dependant on the card used to make a payment.</p>
                    <p>Here are some examples of typical costs...</p>
                  </v-card-text>
                  <v-expansion-panels
                    flat
                    multiple
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        When a pilot pays with a UK card
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col
                            md="4"
                            class="fill-height"
                          >
                            <v-card
                              outlined
                            >
                              <v-simple-table>
                                <template v-slot:default>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Landing fee
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;10.00
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Stripe fee<br>
                                        <small>1.4% plus 20p</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;0.34
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        AirfieldHub fee<br>
                                        <small>3%</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;0.30
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="font-weight-bold">
                                        Airfield revenue
                                      </td>
                                      <td class="text-right font-weight-bold">
                                        £&nbsp;9.36
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-col>
                          <v-col
                            md="4"
                            class="fill-height"
                          >
                            <v-card
                              outlined
                            >
                              <v-simple-table>
                                <template v-slot:default>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Landing fee
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;20.00
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Stripe fee<br>
                                        <small>1.4% plus 20p</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;0.48
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        AirfieldHub fee<br>
                                        <small>3%</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;0.60
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="font-weight-bold">
                                        Airfield revenue
                                      </td>
                                      <td class="text-right font-weight-bold">
                                        £&nbsp;18.92
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-col>
                          <v-col
                            md="4"
                            class="fill-height"
                          >
                            <v-card
                              outlined
                            >
                              <v-simple-table>
                                <template v-slot:default>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Landing fee
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;40.00
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Stripe fee<br>
                                        <small>1.4% plus 20p</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;0.76
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        AirfieldHub fee<br>
                                        <small>3%</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;1.20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="font-weight-bold">
                                        Airfield revenue
                                      </td>
                                      <td class="text-right font-weight-bold">
                                        £&nbsp;38.04
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        When a pilot pays with a non-UK card
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col
                            md="4"
                            class="fill-height"
                          >
                            <v-card
                              outlined
                            >
                              <v-simple-table>
                                <template v-slot:default>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Landing fee
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;10.00
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Stripe fee<br>
                                        <small>2.9% plus 20p</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;0.49
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        AirfieldHub fee<br>
                                        <small>3%</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;0.30
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="font-weight-bold">
                                        Airfield revenue
                                      </td>
                                      <td class="text-right font-weight-bold">
                                        £&nbsp;9.21
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-col>
                          <v-col
                            md="4"
                            class="fill-height"
                          >
                            <v-card
                              outlined
                            >
                              <v-simple-table>
                                <template v-slot:default>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Landing fee
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;20.00
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Stripe fee<br>
                                        <small>2.9% plus 20p</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;0.78
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        AirfieldHub fee<br>
                                        <small>3%</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;0.60
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="font-weight-bold">
                                        Airfield revenue
                                      </td>
                                      <td class="text-right font-weight-bold">
                                        £&nbsp;18.62
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-col>
                          <v-col
                            md="4"
                            class="fill-height"
                          >
                            <v-card
                              outlined
                            >
                              <v-simple-table>
                                <template v-slot:default>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Landing fee
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;40.00
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Stripe fee<br>
                                        <small>2.9% plus 20p</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;1.36
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        AirfieldHub fee<br>
                                        <small>3%</small>
                                      </td>
                                      <td class="text-right">
                                        £&nbsp;1.20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="font-weight-bold">
                                        Airfield revenue
                                      </td>
                                      <td class="text-right font-weight-bold">
                                        £&nbsp;37.44
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>
      <section class="parallax pricing_bg3">
        <div class="d-flex flex-column justify-space-between align-center my-8">
          <v-btn
            color="homebuttonbg"
            class="homebuttoncolour--text"
            x-large
            to="/register"
          >
            <v-icon left>
              mdi-rocket-launch
            </v-icon>
            Get started today
          </v-btn>
        </div>
      </section>
      <section class="no-parallax shorter_content">
        <footer-bar />
      </section>
    </main>
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar.vue";
export default {
  name: "Features",
  components: {FooterBar},
  computed: {
    options () {
        return {
          duration: 600,
          offset: 100,
          easing: "easeInOutCubic",
          container: "#home_main"
        }
      },
  }
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
</style>
